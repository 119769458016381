const mutations = {
  setSelectedTab(state, payload) {
    state.selectedTab = payload;
  },
  reconstructTab(state) {
    state.nav = state.nav.filter((el) => el.status !== "hidden");
  },
  setPatientRef(state, payload) {
    state.patientRef = payload;
  }
};

export default mutations;
