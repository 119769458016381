<template>
  <div id="pop-over-button">
    <button
      id="popover-button-sync"
      :class="`btn btn-sm btn-pompe-${btnVariant}`"
      @click="$emit('toggle-popover')"
    >
      {{ btnText }}
    </button>
    <b-popover
      :show="show"
      :title="title"
      placement="bottom"
      target="popover-button-sync"
      triggers="click blur"
    >
      <slot name="popover-content">
        {{ btnText }}
      </slot>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "PopoverButton",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    btnVariant: {
      type: String,
      default: "primary",
    },
    btnText: {
      type: String,
      default: "Default Text",
    },
    title: {
      type: String,
      default: "Default Title",
    },
  },
};
</script>
