const mutations = {
  SET_TABS(state, payload) {
    state.tabs = [...payload];
  },
  RECONSTRUCT_TAB(state) {
    state.tabs = state.tabs?.filter((el) => el.status !== "hidden");
  },
};

export default mutations;
