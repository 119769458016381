const mutations = {
  SET_SELECTED_TAB(state, payload) {
    state.selectedTab = payload;
  },
  SET_PATIENT_REF(state, payload) {
    state.patientRef = payload;
  }
};

export default mutations;
