<template>
  <div id="sub-header">
    <div class="sub-text text-right" v-b-toggle.sidebar-right>
      <mq-layout :mq="['md', 'lg', 'xl']">
        <span class="identity-text">Hello, {{ userData.firstName }} {{ userData.lastName }}</span>
        <b-icon icon="caret-down-fill" class="dropdown-icon ml-1" font-scale="1" />
      </mq-layout>

      <mq-layout :mq="['sm', 'xs']">
        <b-avatar class="profile-avatar ml-2" />
      </mq-layout>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userData: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
#sub-header {
  margin-top: 0.5em;

  .sub-text {
    margin-top: 0.35em;
  }

  .profile-avatar {
    margin-top: -0.3em;
  }

  .dropdown-icon {
    font-size: 13px;
  }
}
</style>
