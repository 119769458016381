import { getPatientRef } from '../../../helper/getPatientRef';

const actions = {
  async reqPatientRef({ commit }, id) {
    try {
      const { data } = await getPatientRef(id);
      commit('SET_PATIENT_REF', data.ref);
    } catch (error) {
      console.error(error);
    }
  },
  setSelectedTab({ commit }, payload) {
    commit('SET_SELECTED_TAB', payload);
  }
}

export default actions;