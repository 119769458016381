<template>
  <b-sidebar id="sidebar-right" backdrop right shadow>
    <div class="ml-3 sidebar-header mt-2">
      <h6 class="ml-2 mb-2">Logged in as</h6>
      <span class="identity-text mt-3 ml-2" style="font-size: 16px">
        {{ userData.firstName }}, {{ userData.lastName }}
      </span>
      <br />
      <span class="identity-text ml-2"> {{ userData.role }} </span> <br />
      <a
        class="anchor-link ml-2"
        style="font-size: 12px"
        @click="goTo('/web/profile')"
        href="javascript:void(0)"
        >View Profile
        <b-icon icon="arrow-right-square" scale="1"></b-icon>
      </a>
    </div>

    <div class="sidebar-menu">
      <ul class="nav nav-pills flex-column mb-auto">
        <li v-for="(item, index) in menu" :key="index">
          <div style="display: flex; margin-top: 0.5em" class="sidebar-link-container pl-2 pt-1 pb-1">
            <b-icon :icon="item.icon" scale="1" class="text-dark"></b-icon>
             <a
              :href="item.href"
              class="link-dark font-weight-light sidebar-link"
              style="width: 100%"
            >
              
              {{ item.text }}
            </a>
          </div>
        </li>
        <li>
          <div style="display: flex; margin-top: 0.5em" class="sidebar-link-container pl-2 pt-1 pb-1">
            <b-icon icon="power" scale="1" class="text-dark"></b-icon>
            <a
              href="javascript:void(0)"
              class="sidebar-link link-dark font-weight-light"
              style="width: 100%"
              @click="logout()"
            >
              Logout
            </a>
          </div> 
        </li>
      </ul>
    </div>
  </b-sidebar>
</template>

<script>
// Vuex
import { mapState } from "vuex";

export default {
  props: {
    logout: {
      type: Function,
      required: true,
    },
    goTo: {
      type: Function,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      menu: (state) => state.sidebar.menus,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/color.scss';

.sidebar-link {
  text-align: left;
  margin-left: 1.3em;
  font-size: 13px;
  color: $primary-ui !important;  

  &:hover {
    text-decoration: none !important;
    color: $button-background !important;
  }
}
</style>

