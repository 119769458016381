const mutations = {
  setSelectedTab(state, payload) {
    state.selectedTab = payload;
  },
  setTabs(state, payload) {
    state.tabs = [...payload];
  },
  reconstructTab(state) {
    state.tabs = state.nav.filter((el) => el.status !== "hidden");
  },
  setData(state, payload) {
    state.content = {...payload};
  },
  setVisitData(state, payload) {
    state.content['visitData'] = [...payload];
  },
  setOptions(state, payload) {
    state.options = {...payload};
  }
};

export default mutations;
