<template>
  <div id="notification" class="notification" :class="`notification--${type}`">
    <div class="notification-content">
      <div class="notification-content__icon">
        <b-icon :icon="icon[type]" scale="2"></b-icon>
      </div>
      <p class="mt-1">
        <span class="h6"> <b> {{ title || titleText[type] }} </b> </span> <br />
        <span v-html="message"></span>
      </p>
      <div class="close" @click="hideNotification"></div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  props: {
    type: {
      type: String,
    },
    message: {
      type: String,
    },
    title: {
      type: String,
      default: ''
    },
  },
  computed: {
    sanitizedMessage() {
      return DOMPurify.sanitize(this.message);
    }
  },
  data: () => {
    return {
      icon: {
        danger: "x-circle",
        info: "info-circle",
        warning: "exclamation-triangle-fill",
        success: "check",
      },
      titleText: {
        danger: "Error!",
        info: "Info",
        warning: "Warning!",
        success: "Success!",
      },
    };
  },
  methods: {
    hideNotification() {
      document.getElementById("notification").style.display = "none";
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/color.scss';

.notification {
  display: inline-block;
  padding: 0.3em 1em 0 1em;
  margin-bottom: 0.8em;
  width: 100%;
  color: #fff;

  &-content {
    margin-top:0.4em;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 10px;

    p {
      font-size: 13px;
      width: 100%;
      flex: 1 1 auto;
      margin-bottom: 5px;
      overflow: hidden;
    }

    &__icon {
      margin-top: 12px;
      margin-bottom: 12px;
      margin-right: 5px;
      width: 50px;
      text-align: center;
    }

    .close {
      color: #fff;
      margin-top: -5px;

      &::after {
        content: "\00D7";
        font-size: 18px !important;
      }

      &:hover::after {
        cursor: pointer !important;
      }
    }
  }

  &--primary {
    border: 1px solid #0275d8;
    color: #0275d8;
  }

  &--warning {
    border: 1px solid #f0ad4e;
    color: #f0ad4e;
  }

  &--success {
    border: 1px solid #5cb85c;
    color:#5cb85c
  }

  &--danger {
    border: 1px solid #d9534f;
    color: #d9534f;
  }

  &--info {
    border: 1px solid $button-background;
    color: $button-background;
  }
}
</style>
