const state = {
  tableData: [],
  selectedTab: "summaryPatientView",
  tabs: [
    {
      label: 'Summary',
      page: 'summaryPatientView',
    },
    {
      label: 'Patient Second Opinion',
      page: 'secondopinions'
    },
    {
      label: 'Treating Physicians',
      page: 'treatingPhysicians'
    },
    {
      label: 'Data Manager',
      page: 'dataManager'
    },
    {
      label: 'Hospital Management',
      page: 'hospitals',
    }
  ]
}

export default state;
