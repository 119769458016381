/**
 * Handle date formatter
 */
import moment from 'moment';

export default {
  methods: {
    formatDateWithTime(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm:ss") : null;
    },
    formatDateNormal(date) {
      return date ? moment(date).format("DD/MM/YYYY") : null;
    },
    formatDateAbvMonth(date) {
      return date ? moment(date).format("DD-MMM-YYYY") : null;
    },
    formatDate(date) {
      return date ? new Date(date) : null;
    }
  }
}