<template>
  <div id="checkbox">
    <label v-if="label !== ''" :for="name"> {{ label }} </label>
    <label v-else-if="label === ''" :for="name"> &nbsp;</label>
     <b-form-checkbox
      :id="`${name}-${index || ''}`"
      v-model="value"
      :inline="inline"
      :class="label !== '' ? 'mt-1' : ''"
      :name="name"
      :switch="type === 'switch' ? true : false"
      :disabled="handleLogicStatement(name, 'disable')"
      @change="$emit('inputEvent', name, index)"
      @input="$emit('input', $event)"
    >
      <label class="mt-1"> {{ text }} </label>
    </b-form-checkbox>
    <error-message-new
      :index="index"
      :errors="errors"
      :fieldName="name"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      default: null,
    },
    showError: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>

<style>

</style>