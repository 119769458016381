/**
 * Mixins for progress loading on PUT request
 */
export default {
  methods: {
    loadingToast() {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        "Data is updating in the background",
        h("b-spinner", { class: ["ml-2"], props: { small: true } }),
      ]);

      this.$bvToast.toast([vNodesMsg], {
        id: "loadingToast",
        toaster: "b-toaster-top-right",
        title: "Processing",
        solid: true,
        appendToast: true,
      });
    }
  }
}