import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Custom Component Registration
import "./componentRegis.js";
// Library Registration
import "./libraryRegis.js";
// Custom Styling
import "./stylingRegis.js"
// Mixin Registration
import "./mixinRegis.js";
// Global Helper Functions
import "./helper/globalFunction";
import "./services/index"; // main controller of http requests
import "./services/config"; // main controller of API domains

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App), 
  router,
  store,
}).$mount("#app");
