/* eslint-disable no-case-declarations */

import Vue from 'vue';
import router from '../router';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies)

/**
 * Error Handler
 * 
 * @param Object
 */
const errorHandler = (error) => {
  let message = '';
  let beMessage = '';
  if (error.response.status) {
    switch (error.response.status) {
      case 401:
        if (error.response.data.errors[0].errorCode === 'token.expired') {
          Vue.$cookies.remove('token');
          Vue.$cookies.remove('refreshToken');

          Vue.$vToastify.error(`Oops! <br> <small> Your session has expired </small> <br> <br> <b><i>click this box to dismiss</i></b>`);
          
          router.push({ name: 'login' })
        } else {
          message = error.message;
          beMessage = error.response.data.errors[0].message;
          Vue.$vToastify.error(`${message} <br> <small> ${beMessage} </small> <br> <br> <b><i>click this box to dismiss</i></b>`);
        }
        break;
      case 423: 
        message = error.response.data.errors[0].message;
        Vue.$vToastify.info(`${message} <br> <br> <b><i>click this box to dismiss</i></b>`);
        return;
      default:
        break;
    }
  }
}

export default errorHandler;