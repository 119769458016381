const days = [
  { day: null, text: 'Select Day' },
  { day: 1, text: 'Monday' },
  { day: 2, text: 'Tuesday' },
  { day: 3, text: 'Wednesday' },
  { day: 4, text: 'Thursday' },
  { day: 5, text: 'Friday' },
  { day: 6, text: 'Saturday' },
  { day: 7, text: 'Sunday' },
];

const months = [
  { month: null, text: 'Select Month' },
  { month: 1, text: 'January' },
  { month: 2, text: 'February' },
  { month: 3, text: 'March' },
  { month: 4, text: 'April' },
  { month: 5, text: 'May' },
  { month: 6, text: 'June' },
  { month: 7, text: 'July' },
  { month: 8, text: 'August' },
  { month: 9, text: 'September' },
  { month: 10, text: 'October' },
  { month: 11, text: 'November' },
  { month: 12, text: 'December' },
];

export {
  days,
  months
};
