<template>
  <div id="dropdown-table-action">
    <b-dropdown
      :size="dropdownSize"
      :text="dropdownText"
      right
      variant="link"
      toggle-class="anchor-link text-decoration-none"
    >
      <b-dropdown-item
        v-for="(item, idx) in items" :key="idx"
        v-show="item.displayCondition ? item.displayCondition(row) : true"
        href="javascript:void(0)"
        :disabled="item.isDisabled ? item.isDisabled(row) : false"
        @click.prevent="item.action(row)"
      >
        {{ item.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'dropdown-table-action',
  props: {
    dropdownSize: {
      type: String,
      default: 'sm',
    },
    dropdownText: {
      type: String,
      default: 'Actions'
    },
    items: {
      type: Array,
      default: () => ([])
    },
    // selected row
    row: {
      type: Object,
      default: () => ({})
    },
    // selected index
    index: {
      type: Number,
      default: null,
    }
  }
}
</script>

<style>

</style>