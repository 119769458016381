import Vue from 'vue';
import axios from "axios";
import errorHandler from '../helper/errorHandler';

import VueCookies from 'vue-cookies';

Vue.use(VueCookies)

const token = Vue.$cookies.get('token');

Vue.prototype.$requestService = {
  get: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .get(url, {
        ...body,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
  put: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .put(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
  post: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `application/json`
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
  postFile: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data`
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
  auth: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .post(url, body, {
        headers: {
          "Content-Type": `application/x-www-form-urlencoded`,
        },
      })
      .then((response) => {
        if (
          response.status === 200 || 
          response.status === 201 || response.status === 204) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
  delete:  (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
) => {
  return axios
    .delete(url, {
      ...body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        try {
          successCallBack(response);
        } catch (error) {
          throw new Error(error);
        }
        return response;
      }
    })
    .catch(async (e) => {
      try {
        errorHandler(e);
        failCallBack(e);
      } catch (error) {
        console.log(e, error);
      }
      return { isError: true };
    });
  },
  patch: (
    url,
    body,
    successCallBack = (status) => console.log("Success", status),
    failCallBack = (status) => console.error("Failed", status)
  ) => {
    return axios
      .patch(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          try {
            successCallBack(response);
          } catch (error) {
            throw new Error(error);
          }
          return response;
        }
      })
      .catch(async (e) => {
        try {
          errorHandler(e);
          failCallBack(e);
        } catch (error) {
          console.log(e, error);
        }
        return { isError: true };
      });
  },
};
