/**
 * Author: Muhamad Rezki (rezkimuhmd.work@gmail.com)
 * This file contains global functions
 * - 
 * 25 Aug 2022 (update)
 * -
 * To prepare for vue 3 migration. This is now exported functions
 * Needs to be imported when used
 */

/**
 * This function will translate logic statement from BE
 * can be used in all components
 * Parameters can be Boolean / String
 * -
 * @params {String/Boolean} statement - If its string, it should contain condition statement
 * @params {String/Number} fieldConfig - contains index property, used incase condition includes index
 */
export const $logicTranslator = (statement, fieldConfig) => {
  // If statement is string evaluates then return else return it.
  if (typeof statement === "string") {
    if (statement.includes('idx')) {
      statement = statement.replaceAll('idx', fieldConfig.index);
    }

    statement = statement.replaceAll("content.", "this.content.");
    statement = statement.replaceAll("'true'", true);
    statement = statement.replaceAll("'false'", true);
  }

  return statement;
};

/**
 * This function will check if array of errors has particular field name or not
 * to decide the state of the input. red border if input is empty
 */
export const $hasField = (object) => {
  const { name, errors, index, parent } = object;

  if (errors && errors.length) {
    const fullField = `${parent}[${index}].${name}`;
    const errorObject = errors.find(
      (el) => el.field === (index !== null ? fullField : name)
    );

    return !errorObject ? null : false;
  }

  return null;
};

/**
 * This function will get info text from options object
 * @param {*} name
 * @param {*} array
 * @returns String
 */
export const $getInfoText = (name, array) => {
  if (array) {
    let found = array.find((el) => {
      if (el.value === name) return el;
    });

    return found.text;
  }

  return "";
};

/**
 * This function will construct an array of string value
 * @param {*}
 */
export const $constructArray = (value, array) => {
  let index = array.findIndex((el) => el === value);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    array.push(value);
  }

  return array;
};

/**
 * This function will convert camel case to title case
 */
export const $toNormalCase = (value) => {
  if (value) {
    const result = value.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return '-';
};
