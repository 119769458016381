const state = {
  menus: [
    {
      text: "Summary",
      href: "/web/summary/summaryPatientView",
      name: "overview",
      icon: 'house-fill'
    },
    {
      text: "Additional Documents",
      href: "/web/additional-documents",
      name: "downloadArea",
      icon: "archive-fill"
    },
    {
      text: 'Information for Healthcare Professionals',
      href: "/web/information-for-healthcare-professionals",
      name: "professionalInfo",
      icon: "info-circle-fill"
    }
  ]
}

export default state;