<template>
  <div id="textarea-components">
    <b-form-group>
      <label v-if="label !== ''" :for="name"> {{ label }} </label>
      <b-form-textarea
        :id="`${name}-${index || ''}`"
        v-model="value"
        :name="name"
        :placeholder="placeholder"
        :rows="rows"
        :max-rows="maxRows"
        :readonly="handleLogicStatement(name, 'disable')"
        :state="$hasField({ name, errors, index, parent })"
        @blur="$emit('blurEvent', name, index)"
        @input="$emit('input', $event)"
      ></b-form-textarea>
      <!-- display error messages -->
      <error-message-new
        v-if="!$hasField({ name, errors, index, parent }) && showError"
        :index="index"
        :errors="errors"
        :fieldName="name"
        :parent="parent"
      />
    </b-form-group>
  </div>
</template>

<script>
import { $hasField } from '@/helper/globalFunction.js';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: "sm"
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    parent: {
      type: String,
      default: ''
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    rows: {
      type: String,
      default: '3'
    },
    maxRows: {
      type: String,
      default: null
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
  methods: { $hasField }
};
</script>

<style></style>
