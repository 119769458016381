import Vue from 'vue';

// Import Libraries
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import VueToastify from "vue-toastify";
import VueMask from 'v-mask'
import { localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import VueMq from 'vue-mq';
import VueCookies from 'vue-cookies';

/**
 * Vee Validate Additional Config
 */
extend('greaterThanZero', {
  message: field => `The ${field} has to be zero or more`,
  validate: value => {
    return Number(value) >= 0
  }
})
extend('decimalRequired', {
  message: () => `Decimals after dot is required`,
  validate: value => {
    return !value.toString().includes('.') || (value.toString().includes('.') && value[2] !== undefined);
  }
})
extend('emailFormat', {
  message: () => 'Email format is not valid',
  validate: value => {
    const regex =  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-]+)$/
    return regex.test(value);
  }
})
localize({
  en: {
    messages:{
      double: () => 'The correct format for decimal value is a number with a dot and decimal(s) after.',
      email: () => 'Email format is not valid',
    }
  },
});
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

/**
 * Vue Mq Additional Config
 */
const vueMqConfig = {
  breakpoints: { // default breakpoints - customize this
    xs: 360, sm: 480, md: 720, lg: 1200, xl: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
};

/**
 * Vue Toastify Config
 */
const vueToastifyConfig = {
  position: 'center-center',
  withBackdrop: true,
  theme: 'light',
  errorDuration: 2000
}

// Use Imported Library
Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToastify, vueToastifyConfig);
Vue.use(VueMask);
Vue.use(VueMq, vueMqConfig);
Vue.use(VueCookies);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);