/**
 * This mixins remove error message after form submit validation
 */
export default {
  methods: {
    removeError(fieldObject) {
      let { field, idx, fullField } = fieldObject;
      let toBeDeletedIndex = null;

      if (this.errors) {
        toBeDeletedIndex = this.errors.find(el => el.field === (idx !== null ? field : fullField));
        this.errors.splice(toBeDeletedIndex, 1);
      }
    },
  },
};
