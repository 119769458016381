<template>
  <div id="dropdown-component">
    <b-form-group>
      <label v-if="label !== ''" :for="name"> 
        {{ label }}
        <span
          v-if="mandatory"
          class="text-danger"
        > 
          *
        </span>
      </label>
      <b-form-select
        :id="`${name}-${index || ''}`"
        :name="name"
        v-model="value"
        :options="options"
        :size="size"
        :state="$hasField({ name, errors, index })"
        :disabled="handleLogicStatement(name, 'disable')"
        @change="$emit('changeEvent', name, index)"
        @input="$emit('input', $event)"
      ></b-form-select>
      <!-- display error messages -->
      <error-message-new
        :index="index"
        :errors="errors"
        :fieldName="name"
      />
    </b-form-group>
  </div>
</template>

<script>
import { $hasField } from '@/helper/globalFunction.js';

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number, 
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    yearRange: {
      type: String,
      default: `1950-${new Date().getFullYear()}`
    },
    size: {
      type: String,
      default: "sm",
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => []
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    options() {
      let splitYear = this.yearRange.toString().split('-');
      let options = [
        { text: 'Select Year Of Birth', value: null }
      ];

      for (let i = splitYear[0]; i <= splitYear[1]; i++) {
        options.push({ text: i, value: i });
      }

      return options;
    }
  },
  methods: { $hasField }
};
</script>

<style></style>
