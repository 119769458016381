<template>
  <div id="new-error-message">
    <small class="text-danger"> {{ message }} </small>
  </div>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: null,
    },
    parent: {
      type: String,
      default: "",
    },
  },
  computed: {
    message() {
      if (this.errors && this.errors.length) {
        const fullField = `${this.parent}[${this.index}].${this.fieldName}`;
        const errorObject = this.errors.find(
          (el) =>
            el.field === (this.index !== null ? fullField : this.fieldName)
        );

        return errorObject ? errorObject.message : "";
      }

      return "";
    },
  },
};
</script>
