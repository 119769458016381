<template>
  <b-card no-body class="mb-2">
    <b-card-header
      header-tag="header"
      class="p-1 accordion-header"
      role="tab"
      @click="$emit('toggle-collapse')"
    >
      <h6 class="mt-1 ml-2">{{ title }}</h6>

      <b-icon
        class="mt-2 mr-2"
        :icon="isToggled ? 'chevron-up' : 'chevron-down'"
        scale="1"
      ></b-icon>
    </b-card-header>
    <b-collapse
      :id="id"
      :visible="isToggled"
      :accordion="`${id}-accordion`"
      role="tabpanel"
    >
      <b-card-body>
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    isToggled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
