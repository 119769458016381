import state from "./state";
import mutations from "./mutations";
import actions from './actions.js';

const patientAssessment = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default patientAssessment;