import Vue from 'vue';

// Global Mixins
import startProcessMixins from "@/mixins/startProcessMixins";
import navigationMixins from "./mixins/navigationMixins";
import loadingMixins from "./mixins/loadingMixins";
import fieldStateMixins from "./mixins/fieldStateMixins";
import httpRequestMixins from "@/mixins/formPages/httpRequestMixins";
import dateFormatterMixins from "@/mixins/dateFormatterMixins";
import removeErrrorMessageMixins from "@/mixins/formPages/removeErrorMessageMixins";
import constructPdfTableMixins from "@/mixins/constructPdfTableMixins";

Vue.mixin(startProcessMixins);
Vue.mixin(navigationMixins);
Vue.mixin(loadingMixins);
Vue.mixin(fieldStateMixins);
Vue.mixin(httpRequestMixins);
Vue.mixin(dateFormatterMixins);
Vue.mixin(removeErrrorMessageMixins);
Vue.mixin(constructPdfTableMixins);