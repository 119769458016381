import Vue from 'vue';

// import global components
import Breadcrumb from "./components/shared/Breadcrumb.vue";
import CustomBreadcrumb from "./components/shared/CustomBreadcrumb.vue";
import Notification from "./components/shared/Notification.vue";
import ErrorMessage from "./components/shared/ErrorMessage.vue";
import ErrorMessageNew from './components/shared/ErrorMessageNew.vue';
import SubHeader from "./components/layouts/SubHeader";

// global form components
import TextField from "./components/reusable/TextField.vue";
import Dropdown from "./components/reusable/Dropdown.vue";
import DatePicker from "./components/reusable/Datepicker.vue";
import RadioButton from "./components/reusable/RadioButton.vue";
import Checkbox from "./components/reusable/Checkbox.vue";
import TextArea from "./components/reusable/TextArea.vue";
import Autocomplete from "./components/reusable/Autocomplete.vue"
import YearPicker from './components/reusable/YearPicker.vue';
import DetailText from './components/reusable/DetailText.vue'
import CollapsibleContainer from './components/reusable/CollapsibleContainer.vue';
import PompeTable from './components/reusable/PompeTable.vue';
import DropdownTableAction from './components/reusable/DropdownTableAction.vue';
import SeparateDatePicker from './components/reusable/SeparateDatePicker.vue';
import DecimalInput from './components/reusable/FormFields/DecimalInput.vue';
import PhoneNumberInput from './components/reusable/FormFields/PhoneNumber.vue';
import PopoverButton from './components/reusable/FormFields/PopoverButton.vue';

// use global component
Vue.component("custom-breadcrumb", CustomBreadcrumb);
Vue.component("breadcrumb", Breadcrumb);
Vue.component("notification", Notification);
Vue.component("error-message", ErrorMessage);
Vue.component("error-message-new", ErrorMessageNew);
Vue.component("sub-header", SubHeader);

// Global form component
Vue.component("text-field", TextField);
Vue.component("dropdown", Dropdown);
Vue.component("datepicker", DatePicker);
Vue.component("radiobutton", RadioButton);
Vue.component("checkbox", Checkbox);
Vue.component("text-area", TextArea);
Vue.component("autocomplete", Autocomplete)
Vue.component("yearpicker", YearPicker);
Vue.component("detail-text", DetailText);
Vue.component("collapsible-container", CollapsibleContainer);
Vue.component("pompe-table", PompeTable);
Vue.component("dropdown-table-action", DropdownTableAction);
Vue.component("separate-date-picker", SeparateDatePicker)
Vue.component("decimal-input", DecimalInput);
Vue.component("phone-number-input", PhoneNumberInput)
Vue.component("pop-over-button", PopoverButton);