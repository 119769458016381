<template>
  <div id="separate-date-picker">
    <div>
      <label for="parentlabel"> {{ parentLabel }} </label>
    </div>
    <div class="row">
      <div 
        class="col"
      >
        <b-form-group>
          <label> {{ dayLabel }} </label>
          <b-form-select
            :id="`${dayField}-${index || ''}`"
            :name="`${dayField}-day-field`"
            v-model="dayValue"
            :options="dayDropdown"
            :size="size"
            @change="$emit('changeEvent', name, index)"
          ></b-form-select>
        </b-form-group>
      </div>
      <div 
        class="col"
      >
        <b-form-group>
          <label> {{ monthLabel }} </label>
          <b-form-select
            :id="`${monthField}-${index || ''}`"
            :name="`${monthField}-month-field`"
            v-model="monthValue"
            :options="monthDropdown"
            :size="size"
            @change="$emit('changeEvent', name, index)"
          ></b-form-select>
        </b-form-group>
      </div>
      <div 
        class="col"
      >
        <b-form-group>
          <label> {{ yearLabel }} </label>
          <b-form-select
            :id="`${yearField}-${index || ''}`"
            :name="`${yearField}-year-field`"
            v-model="yearValue"
            :options="yearDropdown"
            :size="size"
            @change="$emit('changeEvent', name, index)"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { days, months } from '@/config/separateDatePicker.js';

export default {
  props: {
    parentLabel: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
    showDayField: {
      type: Boolean,
      default: true,
    },
    dayLabel: {
      type: String,
      default: 'Date'
    },
    dayField: {
      type: String,
      default: 'date',
    },
    dayModel: {
      type: Number,
      default: 1,
    },
    showMonthField: {
      type: Boolean,
      default: true,
    },
    monthLabel: {
      type: String,
      default: 'Month',
    },
    monthModel: {
      type: Number,
      default: 1,
    },
    monthField: {
      type: String,
      default: 'month'
    },
    showYearField: {
      type: Boolean,
      default: true,
    },
    yearModel: {
      type: String,
      default: new Date().getFullYear()
    },
    yearLabel: {
      type: String,
      default: 'Year',
    },
    yearField: {
      type: String,
      default: 'year'
    },
    yearRange: {
      type: String,
      default: `1950-${new Date().getFullYear()}`,
    },
    size: {
      type: String,
      default: 'sm'
    }
  },
  data: () => ({
    dayDropdown: days,
    monthDropdown: months
  }),
  computed: {
    dayValue: {
      get: function () {
        return this.dayModel;
      },
      set: function (newVal) {
        this.$emit("updateFieldValue", { 
          parent: this.parent, 
          field: this.name, 
          value: newVal, 
          index: this.index 
        });
      },
    },
    monthValue: {
      get: function () {
        return this.monthModel;
      },
      set: function (newVal) {
        this.$emit("updateFieldValue", { 
          parent: this.parent, 
          field: this.name, 
          value: newVal, 
          index: this.index 
        });
      },
    },
    yearValue: {
      get: function () {
        return this.yearModel;
      },
      set: function (newVal) {
        this.$emit("updateFieldValue", { 
          parent: this.parent, 
          field: this.name, 
          value: newVal, 
          index: this.index 
        });
      },
    },
    yearDropdown() {
      let splitYear = this.yearRange.toString().split('-');
      let options = [
        { text: 'Select Year', value: null }
      ];

      for (let i = splitYear[0]; i <= splitYear[1]; i++) {
        options.push({ number: i, text: i });
      }

      return options;
    }
  }
}
</script>