<template>
  <div id="error-message">
    <ul>
      <li v-for="(error, idx) in errorItems" :key="idx">
        <small class="text-danger"> {{ error.message }} </small>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    fieldName: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: null
    },
  },
  data: () => ({
    errorItems: [],
    multiArrayError: [],
    parentArray: "",
  }),
  watch: {
    errors(val) {
      if (val) {
        this.errorItems = []
        this.multiArrayError = [];

        if (this.index !== null && this.index > -1) {
          this.multiArrayError = Object.keys(val);

          this.multiArrayError.forEach(el => {
            if (el.includes('[')) {
              this.parentArray = el.split('[')[0];
              let propertyName = `${this.parentArray}[${this.index}].${this.fieldName}`;
              
              if (val[propertyName]) 
                this.errorItems = [this.errorItems, ...val[propertyName]];
            }
          })
        } else {
          if (val[this.fieldName]) 
            this.errorItems = [this.errorItems, ...val[this.fieldName]];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin-top: 3px;
  margin-bottom: 0;
  padding: 0;
}
</style>
