<template>
  <div id="datepicker-component">
    <b-form-group>
      <label v-if="label !== ''" :for="name"> 
        {{ label }} 
        <span
          v-if="mandatory"
          class="text-danger"
        > 
          *
        </span>
      </label>

      <br />

      <date-picker
        :id="`${name}-${index || ''}`"
        :name="name"
        v-model="localValue"
        :inputClass="errorState"
        class="custom-date-picker"
        format="DD/MM/YYYY"
        :placeholder="placeholder"
        :size="size"
        :disabled="disabledState"
        @change="$emit('changeEvent', name, index)"
        @input="$emit('input', $event)"
      ></date-picker>
      <!-- display error messages -->
      <error-message-new
        v-if="!$hasField({ name, errors, index, parent }) && showError"
        :index="index"
        :errors="errors"
        :fieldName="name"
        :parent="parent"
      />
    </b-form-group>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { $hasField } from '@/helper/globalFunction.js';

export default {
  components: {
    DatePicker,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    index: {
      type: Number, 
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: "sm"
    },
    value: {
      type: [ String, Date ],
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: null
    },
    parent: {
      type: String,
      default: null
    },
    showError: {
      type: Boolean,
      default: true,
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    errors: {
      type: [Array],
      default: () => []
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    localValue: null
  }),
  watch: {
    value: {
      handler(val) {
        this.localValue = val ? new Date(val) : null;
      },
      immediate: true,
    }
  },
  computed: {
    disabledState() {
      if (this.isDisable !== null) {
        return this.isDisable;
      }

      return this.handleLogicStatement(this.name, 'disable');
    },
    errorState() {
      return (this.$hasField({ 
        name: this.name, 
        errors: this.errors, 
        index: this.index, 
        parent: this.parent 
      }) && this.showError) === false ? 'mx-input error-state' : 'mx-input'
    },
  },
  methods: { $hasField }
}
</script>

<style lang="scss">
.custom-date-picker {
  width: 100%;
}
.normal-border {
  border-color: #ced4da;
}
</style>