const mutations = {
  setSelectedTab(state, payload) {
    state.selectedTab = payload;
  },
  setNavs(state, payload) {
    state.tabs = [...payload];
  },
  setLoadingComponent(state, payload) {
    state.componentLoading = payload;
  },
  reconstructTab(state) {
    state.tabs = state.tabs.filter(el => el.status != "hidden");
  },
  setHiddenTab(state, payload) {
    const index = state.tabs.findIndex(el => el.page === payload);
    state.tabs[index]['status'] = 'hidden'
  },
  setTableData(state, payload) {
    state.tableData = [...payload];
  }
};

export default mutations;
