<template>
  <div id="breadcrumb">
    <nav class="breadcrumb-outer" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(item, idx) in items"
          :key="idx"
          class="breadcrumb-item"
          :class="{ active: item.active }"
          aria-current="page"
        >
          <a
            v-if="item.action"
            href="javascript:void(0)"
            @click="item.action"
          >
            {{ item.text }}
          </a>
          <span v-else> {{ item.text }} </span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "CustomBreadcrumb",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/color.scss';
nav {
  margin-bottom: 0.5em;
}
.breadcrumb {
  margin: 0;
  font-size: 0.8em;
  background-color: #f9f9f9;

  &-item {
    margin-top: 5px;
  }
}
a {
  text-decoration: none;
  color: $primary-ui !important;
  font-weight: bold;

  &:hover {
    color: $primary-ui !important;
  }
}
.breadcrumb-container {
  margin: 10px;
  border-radius: 8px;
  padding: 5px 20px;
  background-color: #f9f9f9;
  font-size: 0.8em;
}
</style>
