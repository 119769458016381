<template>
  <div id="footer" class="footer" :class="isForm ? 'relative' : 'fixed'">
    <div class="footer__top">
      <div class="footer__top-logo">
        <img
          class="sanofi-logo-footer"
          :src="`${getDomain}/api/v1/files/6de6d843-7478-40f9-b384-865ac491caa3/content`"
        />
      </div>
      <div class="footer__top-socmed">
        <!-- <socmed-section /> -->
      </div>
    </div>
    <div class="footer__bottom">
      <div class="footer__bottom-sitemap">
        Contact: <br/>
        Phone: +41 058 440 21 00 <br />
        Email: <a href="mailto:contact.ch@sanofi.com" target="_blank">contact.ch@sanofi.com</a>
        <br />
        <a href="https://www.sanofi.ch/de/mentions-legales" target="_blank">
          Legal mention
        </a>
        <div class="mt-2">
          <a class="anchor-link" href="https://www.sanofi.ch/de/protection-des-donn%C3%A9es/SACH" target="_blank">
            Datenschutz
          </a>
          <br />
          <a class="anchor-link" href="https://www.sanofi.ch/fr/protection-des-donn%C3%A9es/SACH" target="_blank">
            Protection des données
          </a>
        </div>
      </div>
      <div class="footer__bottom-text">
        <span v-html="footerText"></span>
      </div>
    </div>
  </div>
</template>

<script>
// import SocmedSection from "./SocialMedia.vue";

export default {
  name: "PompeFooter",
  components: {
    // SocmedSection,
  },
  computed: {
    getDomain() {
      return process.env.VUE_APP_DOMAIN;
    },
    footerText() {
      return `Website for pompe disease treaters in Switzerland <br />
      &copy;${new Date().getFullYear()} sanofi-aventis (schweiz) ag. All rights reserved. <br />
      Approval Nr: MAT-CH-2200959-v2.0-08/2024
      `;
    },
    isForm() {
      return (
        this.$route.path.includes("add-new-patient") ||
        this.$route.path.includes("assessment-data") ||
        this.$route.path.includes("profile") ||
        this.$route.path.includes("create") ||
        this.$route.path.includes("edit") ||
        this.$route.path.includes("overview") ||
        this.$route.path.includes("summary") ||
        this.$route.path.includes("login") ||
        this.$route.path.includes("information") ||
        this.$route.path.includes("documents") ||
        this.$route.path.includes("evaluation") ||
        this.$route.path.includes("password")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";
@import "@/style/responsive.scss";

.footer {
  width: 100%;
  bottom: 0;

  @include mediumInBetween {
    position: fixed; 
  }

  &.relative {
    position: relative;
  }

  &.fixed {
    position: fixed;
    margin-top: 100px;
  }

  &.absolute {
    position: absolute;
    bottom: 0;
  }

  &__top {
    background-color: #000;
    height: 10em;
    text-align: left;

    .sanofi-logo-footer {
      width: 250px;
      margin-left: 18em;
      margin-top: 1.2em;
    }
  }

  &__bottom {
    font-size: 13px;
    background-color: $footer-bottom;
    height: 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 10em;
    padding-right: 10em;
    padding-bottom: 10px;

    &-sitemap {
      a {
        color: $footer-bottom-text;
      }

      width: 30%;
      text-align: left;
      padding-left: 3em;
      padding-top: 1em;
    }

    &-text {
      width: 30%;
      text-align: left;
      padding: 1em;
      color: $footer-bottom-text;
    }
  }
}
</style>
