<template>
  <div id="dropdown-component">
    <b-form-group>
      <label 
        v-if="label !== ''" 
        :for="name"
      > 
        {{ label }}
        <span 
          v-if="mandatory" 
          class="text-danger"
        >
          *
        </span>
      </label>
      <b-form-select
        v-model="localValue"
        :id="`${name}-${index || ''}`"
        :name="name"
        :options="options"
        :size="size"
        :state="fieldState"
        :disabled="handleLogicStatement(name, 'disable') || isDisable"
        @change="$emit('changeEvent', name, index)"
        @input="$emit('input', $event)"
      ></b-form-select>
      <error-message-new
        v-if="!fieldState"
        :index="index"
        :errors="errors"
        :fieldName="name"
        :parent="parent"
      />
    </b-form-group>
  </div>
</template>

<script>
import { $hasField } from '@/helper/globalFunction.js';
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number, 
      default: null
    },
    value: {
      type: [ String, Number ],
      default: null
    },
    options: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: "sm",
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      default: null
    },
    showError: {
      type: Boolean,
      default: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.localValue = val;
      },
      immediate: true,
    }
  },
  computed: {
    fieldState() {
      return this.$hasField({ 
        name: this.name, 
        errors: this.errors, 
        index: this.index, 
        parent: this.parent 
      }) && this.showError
    },
  },
  methods: {
    $hasField
  }
};
</script>

<style></style>
