<template>
  <div id="text-field-component">
    <b-form-group>
      <label 
        v-if="label !== ''" 
        :for="name"
      > 
        {{ label }}
        <span
          v-if="mandatory"
          class="text-danger"
        >  
          * 
        </span>
      </label>
      <ValidationProvider 
        :name="label" 
        rules="double|greaterThanZero|decimalRequired" 
        v-slot="{ errors }"
      >
        <b-form-input
          :id="`${name}-${index || ''}`"
          type="text"
          autocomplete="off"
          v-model="localValue"
          :placeholder="placeholder"
          :name="name"
          :size="size"
          :state="fieldState"
          :readonly="handleLogicStatement(name, 'disable') || isDisable"
          @blur="$emit('blurEvent', name, index)"
          @input="$emit('input', $event)"
        ></b-form-input>
        <small class="text-muted"> {{ additionalMessage }} </small>
        <small class="text-danger"> {{ errors[0] }} </small>
      </ValidationProvider>
      <error-message-new
        v-if="!fieldState"
        :index="index"
        :errors="errors"
        :fieldName="name"
        :parent="parent"
      />
    </b-form-group>
  </div>
</template>

<script>
import { $hasField } from '@/helper/globalFunction.js';

export default {
  props: {
    mandatory: {
      type: Boolean,
      default: false,
    },
    logic: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "sm"
    },
    isDisable: {
      type: Boolean,
      default: null,
    },
    parent: {
      type: String,
      default: null,
    },
    additionalMessage: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    localValue: '',
  }),
  watch: {
    value: {
      handler(val) {
        this.localValue = val;
      },
      immediate: true
    }
  },
  computed: {
    fieldState() {
      return this.$hasField({ 
        name: this.name, 
        errors: this.errors, 
        index: this.index, 
        parent: this.parent 
      }) && this.showError
    },
  },
  methods: { $hasField }
}
</script>
