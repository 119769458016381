import axios from 'axios';
import Vue from 'vue';

import VueCookies from 'vue-cookies';
Vue.use(VueCookies)

import { $apiEndpoints } from '../services/config';

export const getPatientRef = async (id) => {
  try {
    const domain = process.env.VUE_APP_DOMAIN + "/api/v1";
    const response = await axios.get(domain + `/patients/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + Vue.$cookies.get('token')
      }
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export const getDemographicData = async (id) => {
  try {
    const url = $apiEndpoints.getSpecificPage + '/demographicData?businessKey=' + id;
    const response = await axios.get(url, { headers: {
      'Authorization': 'Bearer ' + Vue.$cookies.get('token')
    }});
    return response.data.content.demographicData.patientRef;
  } catch (error) {
    console.error(error);
  }
}
