<template>
  <div id="breadcrumb">
    <nav class="breadcrumb-outer" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(item, idx) in breadcrumbItem"
          :key="idx"
          class="breadcrumb-item"
          :class="defineActive(item.href, current) ? 'active' : ''"
          aria-current="page"
        >
          <router-link
            v-if="defineActive(item.href, current) && current !== '/overview'"
            :to="item.href"
          >
            {{ item.name }}
          </router-link>
          <span v-else> {{ item.name }} </span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  props: {
    item: {
      type: Array,
      required: true,
    },
    current: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      breadcrumbItem: [
        {
          name: "Home",
          href: "/",
        },
      ],
    };
  },
  mounted() {
    let tempItem = [...this.item];

    tempItem.forEach((el) => {
      if (el.name !== "overview") {
        let name = this.removeSymbol(el.path);
        this.breadcrumbItem.push({
          name: this.capitalizeFirst(name),
          href: el.path,
        });
      }
    });
  },
  methods: {
    defineActive(path, current) {
      return current.includes(path) && current !== path;
    },
    removeSymbol(string) {
      string = string.replaceAll("/", "");
      if (string.includes(":")) string = string.split(":")[0];
      return string.replaceAll("-", " ");
    },
    capitalizeFirst(string) {
      if (string.includes(" ")) {
        let stringSplitted = string.split(" ");

        stringSplitted = stringSplitted.map(el => {
          console.log(el)
          if (el !== 'for') {
            return el.charAt(0).toUpperCase() + el.slice(1)
          }

          return el;
        });
        string = stringSplitted.join(" ")
      }
      string = string.replaceAll("web", "");
      string = string.replaceAll("Web", "");
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/color.scss';
nav {
  margin-bottom: 0.5em;
}
.breadcrumb {
  margin: 0;
  font-size: 0.8em;
  background-color: #f9f9f9;

  &-item {
    margin-top: 5px;
  }
}
a {
  text-decoration: none;
  color: $primary-ui !important;
  font-weight: bold;

  &:hover {
    color: $primary-ui !important;
  }
}
.breadcrumb-container {
  margin: 10px;
  border-radius: 8px;
  padding: 5px 20px;
  background-color: #f9f9f9;
  font-size: 0.8em;
}
</style>
